cookie-dialog {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000045;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
cookie-dialog * {
  box-sizing: border-box;
}
cookie-dialog [name=frame] {
  max-width: 100%;
  max-height: 100%;
  width: 470px;
  height: 290px;
  background-color: white;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
}
cookie-dialog [name=frame] header,
cookie-dialog [name=frame] div {
  text-align: center;
  padding-bottom: 30px;
  width: 100%;
}
cookie-dialog [name=frame] header {
  font-size: 23px;
}
cookie-dialog [name=frame] div {
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}
cookie-dialog [name=frame] div button {
  cursor: pointer;
  text-transform: uppercase;
  height: 70px;
  width: 160px;
  border: none;
  border-radius: 3px;
  color: white;
  font-weight: bold;
  background: var(--primary-background);
}
cookie-dialog [name=frame] div button:hover {
  background: var(--primary-background-hover);
}
cookie-dialog [name=frame] div button[primary] {
  background: var(--seconday-background);
  color: black;
}
cookie-dialog [name=frame] div button[primary]:hover {
  background: var(--seconday-background-hover);
}
